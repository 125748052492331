<template>
    <div class="searchPageLayout">
        <v-app>
            <!-- authErrorFlag Dialog Start -->
            <div justify="center">
                <v-dialog v-model="authErrorFlag" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal">{{ $t("Warning_Message.Session_Expired_Please_Login_Again") }}</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="closeAuthErrorDialog">{{ $t("Warning_Message.Login") }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <!-- authErrorFlag Dialog End -->

            <!-- errorFlag Dialog Start -->
            <div justify="center">
                <v-dialog v-model="errorFlag" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal">
                            {{ $t("Warning_Message.Something_Went_Wrong") }}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed
                                color="primary" @click="errorFlag = false">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <!-- errorFlag Dialog End -->

            <!-- restartAppFlag Dialig Start -->
            <div>
                <v-row justify="center">
                    <v-dialog v-model="restartAppFlag" persistent max-width="290">
                        <v-card>
                            <v-card-title style="word-break:normal">
                                {{ $t("Warning_Message.Restart_App") }}
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn depressed color="primary" @click="restartAppFlag = false;">
                                    {{ $t("Warning_Message.Ok") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </div>
            <!-- restartAppFlag Dialog End -->
            <div class="pa-4" v-if="isLoading">
                <div class="d-flex flex-wrap justify-space-between align-center mt-2 pa-1">
                    <div style="width: 33%;" v-for="i in 12" :key="i">
                        <v-skeleton-loader class="pa-1" type="image" width="110px" height="150px"></v-skeleton-loader>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex flex-row pl-3 pr-2" style="height:52px;">
                    <v-text-field 
                        ref="myTextField"
                        style="margin-top:auto; margin-bottom:auto; padding:8px;"
                        v-model="searchModel" 
                        color="grey"
                        solo
                        flat
                        placeholder="Search for Doctors / Specialization"
                        @input="autoSearchAPICall()" 
                        v-on:keyup.enter="searchAPICall()"
                    >
                        <template v-slot:prepend>
                            <img :src="iconUrl" @click="redirectToHomePage()" />
                        </template>
                        <template v-if="searchModel" v-slot:append>
                            <img :src="closeURL" @click="searchModel = ''" />
                        </template>
                    </v-text-field>
                </div>
            </div>
            <div style="width:100%;" class="mt-4" v-if="!isSearchLoading">
                <div class="pl-4 py-3 splClass" v-if="!isSearchLoading && specializations.length > 0">
                    Specialists
                </div>
                <div class="d-flex flex-row ma-2 scrollWebKit" align="left"
                    style="max-height: 30vh; overflow-x: scroll; overflow-y:hidden;"
                >
                    <div style="width: 32%;" class="mx-1" v-for="specializationData in specializations" :key="specializationData.specialization_key">
                        <specializationSubComponent :specialization_data="specializationData" :pageName="pageName"></specializationSubComponent>
                    </div>
                </div>
                <div class="pl-4 py-3 splClass" v-if="!isSearchLoading && ServiceDoctors.length > 0">
                    Doctors
                </div>
                <div
                    class="scrollWebKit mt-2 pl-4 pr-4"
                    style="max-height: 85vh; width: 100%; overflow-y: scroll; overflow-x:hidden; padding-top:10px; padding-bottom: 20px;"
                >
                    <div v-for="doctor in ServiceDoctors" :key="doctor._id" style="padding-top:10px; ">
                        <doctorsComponent :doctor="doctor" :pageName = "pageName" ></doctorsComponent>
                    </div>
                </div>
            </div>
            <div style="width:100%;" class="mt-4" v-if="isSearchLoading">
                <div class="pl-4 py-3 splClass">
                    {{ $t("Customer.VerifyAppointment.Specialization") }}
                </div>
                <div class="d-flex flex-wrap ma-2 mb-3 justify-space-around scrollWebKit" align="left"
                    style="max-height: 30vh; overflow-y: scroll; overflow-x:hidden;"
                >
                    <v-skeleton-loader class="pa-2" type="image" width="125px" height="125px"></v-skeleton-loader>
                    <v-skeleton-loader class="pa-2" type="image" width="125px" height="125px"></v-skeleton-loader>
                </div>
                <div class="pl-4 py-3 splClass">
                    {{ $t("Customer.BookAppointment.Doctors") }}
                </div>
                <div
                    class="scrollWebKit"
                    style="max-height: 50vh; width: 100%; overflow-y: scroll; overflow-x:hidden; padding-top:10px;"
                >
                    <v-skeleton-loader class="pa-2" type="image" width="100%" height="96px"></v-skeleton-loader>
                    <v-skeleton-loader class="pa-2" type="image" width="100%" height="96px"></v-skeleton-loader>
                    <v-skeleton-loader class="pa-2" type="image" width="100%" height="96px"></v-skeleton-loader>
                </div>
            </div>
            <div style="margin-top:40%" v-if="!isSearchLoading && !ServiceDoctors.length > 0 && !specializations.length > 0">
                <div v-if="!initialLoad">
                    <img src = 'https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/no_results_found_icon.svg'/>
                </div>
                <div v-if="!initialLoad">
                    No Results Found
                </div>
                <div v-if="initialLoad && !isSearchLoading" style="margin-top: 10%">
                    Type Something to Search
                </div>
            </div>
        </v-app>
    </div>
</template>

<script>
import * as heatmap from 'heatmap.js';
import axios from 'axios';

export default ({
    name: 'SearchPage',
    components: {
        specializationSubComponent: () => import('../../../components/specializationSubComponent.vue'),
        doctorsComponent:() => import('../../../components/doctorsComponent.vue')
    },
    data() {
        return {
            token: '',
            closeURL:'https://d3vsnl8idgwrlw.cloudfront.net/popupClose.svg',
            iconUrl: 'https://img.icons8.com/material-outlined/20/000000/left.png',
            isMobile: false,
            showHeaderFlag: true,
            isLoading: true,
            authErrorFlag: false,
            errorFlag: false,
            restartAppFlag: false,
            extradivs: 0,
            loggenInFlag: false,
            isSearchLoading: true,
            specializations: [],
            ServiceDoctors: [],
            searchModel: '',
            initialLoad: true,
            pageName: 'searchPage',
            sendDataTimeout: null
        }
    },
    async mounted() {
        // Get customer token.
        this.token = this.$cookies.get('customerToken');
        this.userPageViewTable();

        // User Agent.
        let userAgent = navigator.userAgent;
        if(userAgent.includes('kbzpay')){
            this.showHeaderFlag = false;
        }

        // Document Title.
        if(window.xm){
            document.title = '';
        } else {
            if(this.$store.state.locale == 'en') {
                document.title = 'Search Doctor';
            } else {
                document.title = 'Search Doctor';
            }
        }

        this.isMobile = this.$device.mobile;

        if (!this.token) {
            this.$i18n.locale = 'mm';
            this.$store.dispatch("changeLocale", 'mm');
            this.loggenInFlag = false;
        } else {
            this.$i18n.locale = this.$store.state.locale;
        }
        // Call searchDoctor API.
        if(!this.initialLoad) {
            this.searchAPICall();
        } else {
            if(localStorage.getItem('lastSearchedString')) {
                this.searchAPICall();
            } else {
                this.isLoading = false;
                this.isSearchLoading = false;
                this.specializations = [];
                this.ServiceDoctors = [];
            }
        }
        this.$nextTick(() => {
            this.$refs.myTextField.focus();
        })
    },
    methods: {
        autoSearchAPICall() {
            if(this.searchModel.length > 3) {
                this.specializations = [];
                this.ServiceDoctors = [];
                this.isLoading = true;
                this.isSearchLoading = true;
                this.searchAPICall();
            }
        },
        async bigQueryHandler() {
            let bigQueryIngestionRequest = null;
            let searchString = this.searchModel ? this.searchModel : localStorage.getItem('lastSearchedString')
            if (this.searchModel === searchString) {
                let serviceDoctors =  this.ServiceDoctors ? this.ServiceDoctors : [];
                let specializations =  this.specializations ? this.specializations : [];
                this.token = await this.$cookies.get('customerToken');
                bigQueryIngestionRequest = {
                    searchString: searchString,
                    serviceDoctors: serviceDoctors,
                    specializations: specializations,
                    token: this.token ? this.token : '',
                    deviceType: 'browser'
                }
                try {
                    let bigQueryHandler = await axios.post(process.env.VUE_APP_BACKEND_URL + '/bigQueryIngestion', bigQueryIngestionRequest);
                } catch (error) {
                    console.log('bigQueryWriteError', error);
                }
            }
        },
        analyticsDebounced() {
            if (this.sendDataTimeout) clearTimeout(this.sendDataTimeout);
            this.sendDataTimeout = setTimeout(() => {
                this.bigQueryHandler();
            }, 1000);
        },
        searchAPICall() {
            this.specializations = [];
            this.ServiceDoctors = [];
            this.isLoading = true;
            this.isSearchLoading = true;
            let searchString = this.searchModel;
            let searchDoctorRequest = {
                token: this.token ? this.token : '',
                typeOfUser: 'CUSTOMER',
                searchString: this.searchModel ? this.searchModel : localStorage.getItem('lastSearchedString')
            };
            axios
                .post(process.env.VUE_APP_BACKEND_URL + '/searchDoctor', searchDoctorRequest)
                .then((searchDoctorResponse) => {
                    this.ServiceDoctors = [];
                    this.specializations = [];
                    if(searchDoctorResponse && searchDoctorResponse.data && searchDoctorResponse.data.data) {
                        if(this.searchModel === searchString) {
                            for(let spl of searchDoctorResponse.data.data.searchSpl) {
                                this.specializations.push(spl);
                            }
                            for(let doc of searchDoctorResponse.data.data.searchDoc) {
                                let everyRecord = {
                                    doctor_id: doc.doctor_id,
                                    doctor_name: this.$i18n.locale === 'mm' ? doc.doctor_name_mm : doc.doctor_name,
                                    doctor_profile_picture: doc.doctor_profile_picture,
                                    fee: doc.service_fee,
                                    position: doc.position,
                                    specialization_name: doc.specialist[0].specialization_name ? doc.specialist[0].specialization_name : doc.specialist[0].name,
                                    _id: doc._id
                                }
                                this.ServiceDoctors.push(everyRecord);
                            }
                            this.isSearchLoading = false;
                            this.analyticsDebounced()
                        }
                    } else {
                        this.initialLoad = false;
                        this.isSearchLoading = false;
                    }
                })
                .catch((searchDoctorError) => {
                    console.log('searchDoctorError', searchDoctorError);
                    if (searchDoctorError.response.status == 401) {
                        this.authErrorFlag = true;
                    } else if (searchDoctorError.response.status == 406) {
                        this.restartAppFlag = true;
                    } else if (searchDoctorError.response.status == 400) {
                        let userAgent = navigator.userAgent;
                        if (window.xm || userAgent.includes('kbzpay')) {
                            this.restartAppFlag = true;
                        } else {
                            this.$cookies.remove("customerToken");
                            this.authErrorFlag = true;
                        }
                    } else if (searchDoctorError.response.status == 403) {
                        this.trustAlertFlag = true;
                    } else {
                        this.errorFlag = true;
                    }
                });
            localStorage.setItem('lastSearchedString', this.searchModel ? this.searchModel : localStorage.getItem('lastSearchedString'));
            this.isLoading = false;
        },
        clearTheSearchField() {
            this.searchModel = '';
        },
        redirectToHomePage() {
            this.$router.go(-1);
        },
        closeAuthErrorDialog() {
            this.authErrorFlag = false;
            this.$router.push({
                name: "Login",
            });
        },
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'SearchPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'SearchPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
                .catch((userPageViewTableError) => {
                    console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
                });
        },
    },
    beforeDestroy() {
        if (this.sendDataTimeout) clearTimeout(this.sendDataTimeout);
    }
});
</script>

<style>
.searchPageLayout {
    max-width: 425px;
    height: 100vh;
    margin-left: auto !important;
    margin-right: auto !important;
}

.headertext{
    font-size: 18px;
    font-weight: bold;
}

.heading{
    font-size: 14px;
    font-weight: bold;
}
.splFont {
    height: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.subheading{
    font-size: 14px;
}
.scrollWebKit::-webkit-scrollbar {
    display: none;
}

.normaltext{
    font-size: 12px;
}

.splClass {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;
    height: 48px;
    background: #F2F2F2;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
</style>
